import * as React from 'react';
import { Link } from 'gatsby';
import { Wrapper, FlexContainer } from './styles';
import Logo from '../../assets/logo';


export interface HeaderProps {
  
}
 
const Header: React.SFC<HeaderProps> = () => {
  return ( 
    <Wrapper>
      <FlexContainer>
        <Link to="/">
          <div style={{
            width: "60vw"
          }}>
          <Logo />
          </div>
        </Link>
      </FlexContainer>
    </Wrapper>

   );
}
 
export default Header;
