import * as React from "react"
import "./layout.css"
import Header from "./header/header"
import Footer from "./footer/footer"

const Layout = (props) => {
  return (
    <div style={{
      background: "linear-gradient(90deg, rgba(255,246,229,1) 0%, rgba(238,204,211,1) 100%)",
      height: "100vh"
    }}>
    <Header />
    <main>
      {props.children}
    </main>
    </div>
  )
}

export default Layout
