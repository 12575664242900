import styled from 'styled-components';

export const Wrapper = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    z-index: 1
`

export const FlexContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`