import * as React from "react"

function Logo(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 870.67 143.88"
      {...props}
    >
      <path d="M145 71.75a74.91 74.91 0 01-5.51 29.1 67.46 67.46 0 01-38.19 37.72 77.79 77.79 0 01-29 5.31 77 77 0 01-28.91-5.31 68.31 68.31 0 01-22.92-14.9 67.48 67.48 0 01-15.05-22.82A76 76 0 010 71.75a76.56 76.56 0 015.42-29.3A65.62 65.62 0 0120.5 19.82a68.66 68.66 0 0122.92-14.6A78.18 78.18 0 0172.33 0a79 79 0 0129 5.22 68.56 68.56 0 0123 14.6 66.86 66.86 0 0115.18 22.63 75.46 75.46 0 015.49 29.3zm-20.69 0a60.92 60.92 0 00-3.67-21.18 53.69 53.69 0 00-10.45-17.5 48.75 48.75 0 00-16.3-11.89 51.39 51.39 0 00-21.56-4.35A50.89 50.89 0 0051 21.18a47.81 47.81 0 00-16.29 11.89 54.67 54.67 0 00-10.34 17.5 60.7 60.7 0 00-3.68 21.18 62.09 62.09 0 003.68 21.47 52.44 52.44 0 0010.44 17.5 48.63 48.63 0 0016.25 11.7 51.42 51.42 0 0021.27 4.25 52.88 52.88 0 0021.47-4.25 48.17 48.17 0 0016.43-11.7 52.47 52.47 0 0010.45-17.5 62.32 62.32 0 003.67-21.47zM177 3.48h41.78a84.75 84.75 0 0120.11 2.23 43.61 43.61 0 0115.47 6.86 31.2 31.2 0 019.86 11.8 39 39 0 013.49 17.21q0 10.24-3.78 17.6a32.35 32.35 0 01-10.44 12A46.74 46.74 0 01237.58 78a83.67 83.67 0 01-19.82 2.23H196.1v60.17H177zM196.1 64h20.31a61.66 61.66 0 0012.86-1.25 32.15 32.15 0 0010.15-3.87A18.21 18.21 0 00246 52a21.6 21.6 0 002.32-10.44 20.35 20.35 0 00-2.42-10.35 19.35 19.35 0 00-6.58-6.76 27.89 27.89 0 00-10-3.68 69.66 69.66 0 00-12.57-1.06H196.1zM300.94 3.48h19.14v57.25h69.24V3.48h19.34V140.4h-19.34V77.74h-69.24v62.66h-19.14zM466.87 123.39h71.55v17h-90.7V3.48h87.8v16.83h-68.65v41h64.59v16.43h-64.59zM594.7 123.39h61.11v17h-80.26V3.48h19.15zM704 140.4h-19.18V3.48H704zM756.37 140.4H735.1L794.47 3.48h17.4l58.8 136.92H849l-13.93-33.65h-65zm20.5-50.47h51.25l-25.53-64.21z" />
    </svg>
  )
}

export default Logo